<template>
	<div class="cw-app-wrapper">
		<!-- contents -->
		<MStepper />
		<div class="cw-app-section">
			<div class="u-app-title">
				{{ isPreContract ? '배정 진행' : '배정 재진행' }}
			</div>

			<div class="u-app-select">
				<p class="desc">
					{{
						isPreContract
							? '사전계약이 완료된 주문의 배정을 진행합니다.'
							: '배정 미완료된 주문만 배정을 재진행합니다.'
					}}
				</p>
				<v-radio-group v-model="radioGroup">
					<v-row v-show="checkedAll && showRadioBtn">
						<v-col cols="4">
							<v-radio
								label="일괄 배정"
								@change="changeRadioGroup(0)"
							></v-radio>
						</v-col>
						<v-col cols="4">
							<v-radio
								label="개별 배정"
								@change="changeRadioGroup(1)"
							></v-radio>
						</v-col>
					</v-row>
				</v-radio-group>
			</div>

			<!-- 일괄배정 -->
			<div class="u-app-details none" v-show="radioGroup === 0">
				<dl class="row full">
					<dt>설치희망일시 {{ isPreContract ? '선택' : '재선택' }}</dt>
					<dd>
						<ul class="order-wrap">
							<li v-if="deliveryGrpInfos.serviceManager.orders.length > 0">
								<div class="order-box">
									<h4>서비스매니저</h4>
									<template
										v-for="(order, index) in deliveryGrpInfos.serviceManager
											.orders"
									>
										<p class="cancle" v-if="order.orderCancelYn" :key="index">
											주문{{ order.orderSeq }}.<span>{{ order.prodNm }}</span
											><em>주문삭제</em>
										</p>
										<p v-else :key="order.orderSeq">
											주문{{ order.orderSeq }}.<span>{{ order.prodNm }}</span>
										</p>
									</template>
								</div>
								<v-btn
									class="btn_row disabled"
									v-if="
										!checkIfOpenScheduleBook(
											deliveryGrpInfos.serviceManager.orders
										)
									"
								>
									<em class="normal">설치일시 선택 불가</em>
								</v-btn>
								<v-btn
									class="btn_row pick"
									v-else
									@click="openSchPopup('serviceManager')"
								>
									<em
										class="normal"
										v-if="deliveryGrpInfos.serviceManager.bookingDate"
									>
										{{ dateToBookingText(deliveryGrpInfos.serviceManager) }}
									</em>
									<em class="normal text_orange" v-else>설치일시 선택</em>
								</v-btn>
							</li>
							<li v-if="deliveryGrpInfos.delegate3PL.orders.length > 0">
								<div class="order-box">
									<h4>외부업체(대형제품)</h4>
									<template
										v-for="(order, index) in deliveryGrpInfos.delegate3PL
											.orders"
									>
										<p class="cancle" v-if="order.orderCancelYn" :key="index">
											주문{{ order.orderSeq }}.<span>{{ order.prodNm }}</span
											><em>주문삭제</em>
										</p>
										<p v-else :key="order.orderSeq">
											주문{{ order.orderSeq }}.<span>{{ order.prodNm }}</span>
										</p>
									</template>
								</div>
								<v-btn
									class="btn_row disabled"
									v-if="
										!checkIfOpenScheduleBook(
											deliveryGrpInfos.delegate3PL.orders
										)
									"
								>
									<em class="normal">설치일시 선택 불가</em>
								</v-btn>
								<v-btn
									class="btn_row pick"
									@click="openSchPopup('delegate3PL')"
									v-else
								>
									<em
										class="normal"
										v-if="deliveryGrpInfos.delegate3PL.bookingDate"
									>
										{{ dateToBookingText(deliveryGrpInfos.delegate3PL) }}
									</em>
									<em class="normal text_orange" v-else>설치일시 선택</em>
								</v-btn>
							</li>
							<li v-if="deliveryGrpInfos.air3PL.orders.length > 0">
								<div class="order-box">
									<h4>외부업체(청정기)</h4>
									<template
										v-for="(order, index) in deliveryGrpInfos.air3PL.orders"
									>
										<p class="cancle" v-if="order.orderCancelYn" :key="index">
											주문{{ order.orderSeq }}.<span>{{ order.prodNm }}</span
											><em>주문삭제</em>
										</p>
										<p v-else :key="order.orderSeq">
											주문{{ order.orderSeq }}.<span>{{ order.prodNm }}</span>
										</p>
									</template>
								</div>
								<v-btn
									class="btn_row disabled"
									v-if="
										!checkIfOpenScheduleBook(deliveryGrpInfos.air3PL.orders)
									"
								>
									<em class="normal">설치일시 선택 불가</em>
								</v-btn>
								<v-btn
									class="btn_row pick"
									@click="openSchPopup('air3PL')"
									v-else
								>
									<em class="normal" v-if="deliveryGrpInfos.air3PL.bookingDate">
										{{ dateToBookingText(deliveryGrpInfos.air3PL) }}
									</em>
									<em class="normal text_orange" v-else>설치일시 선택</em>
								</v-btn>
							</li>
						</ul>
					</dd>
				</dl>
				<dl class="row full mt-8" v-if="deliveryCmpInfos.length > 0">
					<dt>배정완료 현황</dt>
					<dd>
						<ul class="order-wrap">
							<li>
								<div class="order-box">
									<div
										class="items"
										v-for="(deliveryCmpInfo, i) in deliveryCmpInfos"
										:key="i"
									>
										<p>
											주문{{ deliveryCmpInfo.orderSeq }}.<span>{{
												deliveryCmpInfo.prodNm
											}}</span>
										</p>
										<p class="date">
											{{ dateToBookingText(deliveryCmpInfo) }}
										</p>
									</div>
								</div>
							</li>
						</ul>
					</dd>
				</dl>
				<dl class="row full mt-8" v-if="deliveryCanceledInfos.length > 0">
					<dt>주문삭제 내역</dt>
					<dd>
						<ul class="order-wrap">
							<li>
								<div class="order-box">
									<p
										class="cancle"
										v-for="(deliveryCanceledInfo, i) in deliveryCanceledInfos"
										:key="i"
									>
										주문{{ deliveryCanceledInfo.orderSeq }}.<span>{{
											deliveryCanceledInfo.prodNm
										}}</span
										><em>주문삭제</em>
									</p>
								</div>
							</li>
						</ul>
					</dd>
				</dl>
			</div>
			<!-- //일괄배정 -->

			<!-- 개별배정 -->
			<div class="u-app-details none" v-show="radioGroup === 1">
				<dl class="row full">
					<dt>설치희망일시 {{ isPreContract ? '선택' : '재선택' }}</dt>
					<dd>
						<ul class="order-wrap">
							<template v-for="(deliveryIndInfo, i) in deliveryIndInfos">
								<li
									v-if="
										!deliveryIndInfo.bookingYn &&
											deliveryIndInfo.workerType === 'SERVICE_MANAGER'
									"
									:key="i"
								>
									<div class="order-box">
										<h4>서비스매니저</h4>
										<p
											class="cancle"
											v-if="deliveryIndInfo.orderCancelYn"
											:key="i"
										>
											주문{{ deliveryIndInfo.orderSeq }}.<span>{{
												deliveryIndInfo.prodNm
											}}</span
											><em>주문삭제</em>
										</p>
										<p v-else :key="deliveryIndInfo.orderSeq">
											주문{{ deliveryIndInfo.orderSeq }}.<span>{{
												deliveryIndInfo.prodNm
											}}</span>
										</p>
									</div>
									<v-btn
										class="btn_row pick"
										v-if="!deliveryIndInfo.orderCancelYn"
										@click="openSchPopup(i)"
									>
										<em class="normal" v-if="deliveryIndInfo.bookingDate">
											{{ dateToBookingText(deliveryIndInfo) }}</em
										>
										<em class="normal text_orange" v-else>설치일시 선택</em>
									</v-btn>
									<v-btn class="btn_row disabled" v-else>
										<em class="normal">설치일시 선택 불가</em>
									</v-btn>
								</li>
							</template>
							<template v-for="(deliveryIndInfo, i) in deliveryIndInfos">
								<li
									v-if="
										!deliveryIndInfo.bookingYn &&
											deliveryIndInfo.workerType === 'DELEGATE_3PL'
									"
									:key="i"
								>
									<div class="order-box">
										<h4>외부업체(대형제품)</h4>
										<p
											class="cancle"
											v-if="deliveryIndInfo.orderCancelYn"
											:key="i"
										>
											주문{{ deliveryIndInfo.orderSeq }}.<span>{{
												deliveryIndInfo.prodNm
											}}</span
											><em>주문삭제</em>
										</p>
										<p v-else :key="deliveryIndInfo.orderSeq">
											주문{{ deliveryIndInfo.orderSeq }}.<span>{{
												deliveryIndInfo.prodNm
											}}</span>
										</p>
									</div>
									<v-btn
										class="btn_row pick"
										v-if="!deliveryIndInfo.orderCancelYn"
										@click="openSchPopup(i)"
									>
										<em class="normal" v-if="deliveryIndInfo.bookingDate">
											{{ dateToBookingText(deliveryIndInfo) }}</em
										>
										<em class="normal text_orange" v-else>설치일시 선택</em>
									</v-btn>
									<v-btn class="btn_row disabled" v-else>
										<em class="normal">설치일시 선택 불가</em>
									</v-btn>
								</li>
							</template>
							<template v-for="(deliveryIndInfo, i) in deliveryIndInfos">
								<li
									v-if="
										!deliveryIndInfo.bookingYn &&
											deliveryIndInfo.workerType === 'AIR_3PL'
									"
									:key="i"
								>
									<div class="order-box">
										<h4>외부업체(청정기)</h4>
										<p
											class="cancle"
											v-if="deliveryIndInfo.orderCancelYn"
											:key="i"
										>
											주문{{ deliveryIndInfo.orderSeq }}.<span>{{
												deliveryIndInfo.prodNm
											}}</span
											><em>주문삭제</em>
										</p>
										<p v-else :key="deliveryIndInfo.orderSeq">
											주문{{ deliveryIndInfo.orderSeq }}.<span>{{
												deliveryIndInfo.prodNm
											}}</span>
										</p>
									</div>
									<v-btn
										class="btn_row pick"
										v-if="!deliveryIndInfo.orderCancelYn"
										@click="openSchPopup(i)"
									>
										<em class="normal" v-if="deliveryIndInfo.bookingDate">
											{{ dateToBookingText(deliveryIndInfo) }}</em
										>
										<em class="normal text_orange" v-else>설치일시 선택</em>
									</v-btn>
									<v-btn class="btn_row disabled" v-else>
										<em class="normal">설치일시 선택 불가</em>
									</v-btn>
								</li>
							</template>
						</ul>
					</dd>
				</dl>
				<dl class="row full mt-8" v-if="deliveryCmpInfos.length > 0">
					<dt>배정완료 현황</dt>
					<dd>
						<ul class="order-wrap">
							<li>
								<div class="order-box">
									<div
										class="items"
										v-for="(deliveryCmpInfo, i) in deliveryCmpInfos"
										:key="i"
									>
										<p>
											주문{{ deliveryCmpInfo.orderSeq }}.<span>{{
												deliveryCmpInfo.prodNm
											}}</span>
										</p>
										<p class="date">
											{{ dateToBookingText(deliveryCmpInfo) }}
										</p>
									</div>
								</div>
							</li>
						</ul>
					</dd>
				</dl>
				<dl class="row full mt-8" v-if="deliveryCanceledInfos.length > 0">
					<dt>주문삭제 내역</dt>
					<dd>
						<ul class="order-wrap">
							<li>
								<div class="order-box">
									<p
										class="cancle"
										v-for="(deliveryCanceledInfo, i) in deliveryCanceledInfos"
										:key="i"
									>
										주문{{ deliveryCanceledInfo.orderSeq }}.<span>{{
											deliveryCanceledInfo.prodNm
										}}</span
										><em>주문삭제</em>
									</p>
								</div>
							</li>
						</ul>
					</dd>
				</dl>
			</div>
		</div>
		<!-- //개별배정 -->
		<!-- //contents -->

		<!-- bottom -->
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn large depressed class="u-btn-active" @click="next()"
						>배정 진행</v-btn
					>
				</div>
			</div>
		</div>
		<!-- //bottom -->
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import filters from '@/mixins/filters'
import {
	postWebOrderInfoById,
	fetchWebOrderInfoById,
	checkingStatus,
	updateCheckingSchedule
} from '@/apis/order.status.api'
import { dateToBookingText } from '@/utils/schedule.js'
import { registerCallbackToApp } from '@/utils/navigation'
import { fetchEContract } from '@/apis/order.ec.api'
import { sendAlimTalk } from '@/apis/order.ec.api'
import { isParameterEmpty } from '@/utils/common'
export default {
	components: {},
	mixins: [filters],
	data() {
		return {
			mobOrderNo: this.$store.getters['verify/mobOrderNo'],
			deliveryInfos: this.$store.getters['schedule/deliveryInfos'],
			deliveryIndInfos: [], // 개별 미완료 배정 정보
			deliveryGrpInfos: {
				serviceManager: {
					orders: [
						/* {
						 	goodsCd: v.goodsCd,
							prodNm: 'BAS37-A',
							orderSeq: 1,
							orderNo: '',
							bookingYn: false,
							cancelYn: false
						} */
					], // 일괄 미완료 배정 정보
					bookingDate: undefined,
					bookingSlot: undefined,
					bookingSlotTime: undefined,
					zipSeq: undefined,
					key: undefined
				},
				delegate3PL: {
					orders: [],
					bookingDate: undefined,
					bookingSlot: undefined,
					bookingSlotTime: undefined,
					zipSeq: undefined,
					key: undefined
				},
				air3PL: {
					orders: [],
					bookingDate: undefined,
					bookingSlot: undefined,
					bookingSlotTime: undefined,
					zipSeq: undefined,
					key: undefined
				}
			},
			deliveryCmpInfos: [], // 완료된 배정 정보
			deliveryCanceledInfos: [], // 주문삭제된 배정 정보
			radioGroup: 0, // 0: 일괄배정, 1: 개별배정
			showRadioBtn: false,
			checkedAll: this.$store.getters['schedule/checkedAll'],
			isPreContract: this.$store.state['verify'].customer.orderType === 'C'
		}
	},
	async created() {
		this.initAppPage()
		window.$updateDeliveryInfos = this.updateDeliveryInfos.bind(this)
	},
	async mounted() {
		if (!this.checkedAll) {
			this.radioGroup = 1
		}

		//region 취소여부 확인
		const orderNoList = []
		for (const deliveryInfo of this.deliveryInfos) {
			orderNoList.push(deliveryInfo.orderNo)
		}

		const res = await checkingStatus({
			saleCd: this.$store.getters['common/codyNo'],
			kunnr: this.$store.getters['verify/custNo'],
			orderNos: orderNoList
		})

		res.resultObject.T_ORDER_STATUS.forEach((v, i) => {
			if (v.ORDER_STATUS === '203') {
				this.deliveryInfos[i].orderCancelYn = true
			}
		})
		//endregion

		await this.reloadDeliveryInfos()
		this.showRadioBtn = true
	},
	methods: {
		...mapActions({ showSnackbar: 'common/showSnackbar' }),
		dateToBookingText,
		isParameterEmpty,
		updateDeliveryInfos: function(result) {
			sessionStorage.removeItem('schedule')

			const index = result.bookingIndex
			if (this.radioGroup === 0) {
				this.deliveryGrpInfos[index].bookingDate = result.bookingDate
				this.deliveryGrpInfos[index].bookingSlot = result.bookingSlot
				this.deliveryGrpInfos[index].bookingSlotTime = result.bookingSlotTime
				this.deliveryGrpInfos[index].key = result.key

				this.$set(this.deliveryGrpInfos, index, this.deliveryGrpInfos[index])
			} else {
				this.deliveryIndInfos[index].bookingDate = result.bookingDate
				this.deliveryIndInfos[index].bookingSlot = result.bookingSlot
				this.deliveryIndInfos[index].bookingSlotTime = result.bookingSlotTime
				this.deliveryIndInfos[index].key = result.key

				this.$set(this.deliveryIndInfos, index, this.deliveryIndInfos[index])
			}
		},
		async openSchPopup(index) {
			let workerType = ''
			if (this.radioGroup === 0) {
				workerType =
					index === 'serviceManager'
						? 'SERVICE_MANAGER'
						: index === 'air3PL'
						? 'AIR_3PL'
						: 'DELEGATE_3PL'
			} else {
				workerType = this.deliveryIndInfos[index].workerType
			}

			let zipSeq
			let goodsCodeList
			let goodsNameList
			let reqAddress
			let zipCode
			let orderList
			let prodList

			if (this.radioGroup === 0) {
				zipSeq = this.deliveryGrpInfos[index].zipSeq
				reqAddress = this.deliveryGrpInfos[index].address
				zipCode = this.deliveryGrpInfos[index].zipCode
				goodsCodeList = []
				goodsNameList = []
				prodList = []
				orderList = []
				this.deliveryGrpInfos[index].orders.forEach(v => {
					goodsCodeList.push(v.scheduleGoodsCodeList)
					goodsNameList.push(v.prodNm)
					orderList.push(v.scheduleOrderList)
					prodList.push(v.prodh)
				})
			} else {
				zipSeq = this.deliveryIndInfos[index].zipSeq
				goodsCodeList = this.deliveryIndInfos[index].scheduleGoodsCodeList
				reqAddress = this.deliveryIndInfos[index].address
				zipCode = this.deliveryIndInfos[index].zipCode
				goodsNameList = this.deliveryIndInfos[index].prodNm
				orderList = this.deliveryIndInfos[index].scheduleOrderList
				prodList = this.deliveryIndInfos[index].prodh
			}

			const scheduleInfo = {
				index,
				userId: this.$store.getters['common/codyNo'],
				zipSeq,
				workerType,
				goodsCodeList,
				goodsNameList: goodsNameList,
				address: reqAddress,
				zipCode: zipCode,
				orderList: orderList,
				prodList: prodList
			}

			this.$log('scheduleInfo param : ' + JSON.stringify(scheduleInfo))

			sessionStorage.setItem('schedule', JSON.stringify(scheduleInfo))

			const routerData = this.$router.resolve({
				name: 'order-schedule'
			})

			window.open(routerData.href, '_blank')
		},
		async next() {
			if (this.isParameterEmpty(this.mobOrderNo)) {
				return
			}
			//region 설치희망일시 정보 유효성 검사
			const validateResultMsg = await this.submitValidate()
			if (validateResultMsg.length > 0) {
				await this.$alert({
					message: validateResultMsg
				})
				return
			}
			//endregion 설치희망일시 정보 유효성 검사

			let checkingOrderStatus

			//region 주문삭제 확인
			checkingOrderStatus = await this.deliveryInfos.some(v => !v.orderCancelYn)

			if (!checkingOrderStatus) {
				await this.saveWebOrderInfo('203')

				await this.$router.push({ name: 'order-status' })
				return
			}
			//endregion

			//region 배정 재진행 값 세팅
			if (this.radioGroup === 0) {
				this.deliveryGrpInfos.serviceManager.orders.forEach(x => {
					const deliveryInfo = this.deliveryInfos.find(
						y => x.orderSeq === y.orderSeq
					)
					deliveryInfo.bookingDate = this.deliveryGrpInfos.serviceManager.bookingDate
					deliveryInfo.bookingSlot = this.deliveryGrpInfos.serviceManager.bookingSlot
					deliveryInfo.bookingSlotTime = this.deliveryGrpInfos.serviceManager.bookingSlotTime
					deliveryInfo.key = this.deliveryGrpInfos.serviceManager.key
				})

				this.deliveryGrpInfos.delegate3PL.orders.forEach(x => {
					const deliveryInfo = this.deliveryInfos.find(
						y => x.orderSeq === y.orderSeq
					)
					deliveryInfo.bookingDate = this.deliveryGrpInfos.delegate3PL.bookingDate
					deliveryInfo.bookingSlot = this.deliveryGrpInfos.delegate3PL.bookingSlot
					deliveryInfo.bookingSlotTime = this.deliveryGrpInfos.delegate3PL.bookingSlotTime
					deliveryInfo.key = this.deliveryGrpInfos.delegate3PL.key
				})

				this.deliveryGrpInfos.air3PL.orders.forEach(x => {
					const deliveryInfo = this.deliveryInfos.find(
						y => x.orderSeq === y.orderSeq
					)
					deliveryInfo.bookingDate = this.deliveryGrpInfos.air3PL.bookingDate
					deliveryInfo.bookingSlot = this.deliveryGrpInfos.air3PL.bookingSlot
					deliveryInfo.bookingSlotTime = this.deliveryGrpInfos.air3PL.bookingSlotTime
					deliveryInfo.key = this.deliveryGrpInfos.air3PL.key
				})
			} else {
				this.deliveryIndInfos.forEach(x => {
					const deliveryInfo = this.deliveryInfos.find(
						y => x.orderSeq === y.orderSeq
					)
					deliveryInfo.bookingDate = x.bookingDate
					deliveryInfo.bookingSlot = x.bookingSlot
					deliveryInfo.bookingSlotTime = x.bookingSlotTime
					deliveryInfo.key = x.key
				})
			}
			//endregion 배정 재진행 세팅

			//region 배정 진행 후 웹디비 업데이트
			const deliveryParams = { deliveryInfos: [...this.deliveryInfos] }
			const result = await updateCheckingSchedule(
				this.mobOrderNo,
				deliveryParams
			)

			if (result.resultObject.statusCode === '109') {
				if (this.isPreContract) await this.alimTalk('2')
				await this.showSnackbar({
					msg: '배정이 모두 완료되어, 주문확정 되었습니다.',
					color: '#222'
				})
				await this.$router.push({ name: 'order-status' })
				return
			} else {
				await this.$alert({
					message:
						result.resultObject.failSeqStr +
						' 배정이 실패되었습니다. 다시 선택 후 진행해주세요.'
				})
				if (['111', '113'].includes(result.resultObject.statusCode)) {
					this.deliveryInfos = [...result.resultObject.deliveryInfos]
				}
			}
			//endregion 배정 진행 후 웹디비 업데이트

			await this.reloadDeliveryInfos()
		},
		async saveWebOrderInfo(statusCode) {
			const res = await fetchWebOrderInfoById(this.mobOrderNo)
			const webParams = res.resultObject
			webParams.statusCode = statusCode
			let msg
			if (statusCode === '203') {
				webParams.step = '10'
				msg = '영업정보 시스템을 통해 주문이 삭제되었습니다.'
			}

			await postWebOrderInfoById(this.mobOrderNo, webParams)

			if (msg) {
				await this.showSnackbar({
					msg: msg,
					color: '#222'
				})
			}
		},
		async submitValidate() {
			const errorText = '의 설치일시가 선택되지 않았습니다.'
			const bookingNameArr = []

			// 일괄 배정
			if (this.radioGroup === 0) {
				let bookingDate, chkOrderCancel

				bookingDate = this.deliveryGrpInfos.serviceManager.bookingDate
				if (
					!bookingDate &&
					this.deliveryGrpInfos.serviceManager.orders.length > 0
				) {
					chkOrderCancel = this.deliveryGrpInfos.serviceManager.orders.some(
						v => v.orderCancelYn
					)
					if (!chkOrderCancel) {
						bookingNameArr.push('서비스매니저')
					}
				}

				bookingDate = this.deliveryGrpInfos.delegate3PL.bookingDate
				if (
					!bookingDate &&
					this.deliveryGrpInfos.delegate3PL.orders.length > 0
				) {
					chkOrderCancel = this.deliveryGrpInfos.delegate3PL.orders.some(
						v => v.orderCancelYn
					)
					if (!chkOrderCancel) {
						bookingNameArr.push('외부업체(대형제품)')
					}
				}

				bookingDate = this.deliveryGrpInfos.air3PL.bookingDate
				if (!bookingDate && this.deliveryGrpInfos.air3PL.orders.length > 0) {
					chkOrderCancel = this.deliveryGrpInfos.air3PL.orders.some(
						v => v.orderCancelYn
					)
					if (!chkOrderCancel) {
						bookingNameArr.push('외부업체(청정기)')
					}
				}
			} else {
				// 개별 배정
				this.deliveryIndInfos.forEach(v => {
					let orderNoText = '주문' + v.orderSeq

					if (!v.bookingDate && !v.orderCancelYn) {
						bookingNameArr.push(orderNoText)
					}
				})
			}

			let msg = ''
			if (bookingNameArr.length > 0) {
				msg = [...new Set(bookingNameArr)].join(', ') + errorText
			}
			return msg
		},
		async reloadDeliveryInfos() {
			// 배정 상품정보 가져오기
			const res = await fetchEContract({
				mobOrderNo: this.mobOrderNo
			})
			const orderList = res.resultObject.data[0].setup
			this.deliveryInfos.forEach(v => {
				const orderListData = orderList.filter(x => x.ORDER_NO === v.orderNo)[0]
				v.scheduleGoodsCodeList = orderListData.scheduleGoodsCodeList
				v.scheduleOrderList = orderListData.scheduleOrderList
				v.prodNm = orderListData.GOODS_NM
				v.prodh = orderListData.PRODH9?.substr(0, 6)
			})

			// 택배배송 제외
			const deliveryInfos = JSON.parse(
				JSON.stringify(
					this.deliveryInfos.filter(v => v.workerType !== 'EXPRESS')
				)
			)
			// 배정완료 주문 세팅
			this.deliveryCmpInfos = deliveryInfos.filter(
				v => v.bookingYn && !v.orderCancelYn
			)
			this.deliveryCanceledInfos = deliveryInfos.filter(v => v.orderCancelYn)

			// 개별 미완료 세팅
			this.deliveryIndInfos = deliveryInfos.filter(
				v => !v.bookingYn && !v.orderCancelYn
			)
			this.deliveryIndInfos.forEach(v => {
				v.bookingDate = undefined
				v.bookingSlot = undefined
				v.bookingSlotTime = undefined
				v.key = undefined
			})

			// 일괄 미완료 세팅
			this.deliveryGrpInfos.serviceManager.orders = []
			this.deliveryGrpInfos.delegate3PL.orders = []
			this.deliveryGrpInfos.air3PL.orders = []
			const serviceType = []
			const agencyType = []

			this.deliveryIndInfos.forEach(v => {
				if (v.workerType === 'SERVICE_MANAGER') {
					this.deliveryGrpInfos.serviceManager.orders.push({
						goodsCd: v.goodsCd,
						prodNm: v.prodNm,
						orderSeq: v.orderSeq,
						orderNo: v.orderNo,
						bookingYn: v.bookingYn,
						orderCancelYn: v.orderCancelYn,
						scheduleGoodsCodeList: v.scheduleGoodsCodeList,
						scheduleOrderList: v.scheduleOrderList,
						prodh: v.prodh
					})
					this.deliveryGrpInfos.serviceManager.bookingDate = undefined
					this.deliveryGrpInfos.serviceManager.bookingSlot = undefined
					this.deliveryGrpInfos.serviceManager.bookingSlotTime = undefined
					this.deliveryGrpInfos.serviceManager.zipSeq = v.zipSeq
					this.deliveryGrpInfos.serviceManager.key = v.key
					this.deliveryGrpInfos.serviceManager.address = v.address
					this.deliveryGrpInfos.serviceManager.zipCode = v.zipCode
					serviceType.push(v)
				} else if (v.workerType === 'DELEGATE_3PL') {
					this.deliveryGrpInfos.delegate3PL.orders.push({
						goodsCd: v.goodsCd,
						prodNm: v.prodNm,
						orderSeq: v.orderSeq,
						orderNo: v.orderNo,
						bookingYn: v.bookingYn,
						orderCancelYn: v.orderCancelYn,
						scheduleGoodsCodeList: v.scheduleGoodsCodeList,
						scheduleOrderList: v.scheduleOrderList,
						prodh: v.prodh
					})
					this.deliveryGrpInfos.delegate3PL.bookingDate = undefined
					this.deliveryGrpInfos.delegate3PL.bookingSlot = undefined
					this.deliveryGrpInfos.delegate3PL.bookingSlotTime = undefined
					this.deliveryGrpInfos.delegate3PL.zipSeq = v.zipSeq
					this.deliveryGrpInfos.delegate3PL.key = v.key
					this.deliveryGrpInfos.delegate3PL.address = v.address
					this.deliveryGrpInfos.delegate3PL.zipCode = v.zipCode
					agencyType.push(v)
				} else if (v.workerType === 'AIR_3PL') {
					this.deliveryGrpInfos.air3PL.orders.push({
						goodsCd: v.goodsCd,
						prodNm: v.prodNm,
						orderSeq: v.orderSeq,
						orderNo: v.orderNo,
						bookingYn: v.bookingYn,
						orderCancelYn: v.orderCancelYn,
						scheduleGoodsCodeList: v.scheduleGoodsCodeList,
						scheduleOrderList: v.scheduleOrderList,
						prodh: v.prodh
					})
					this.deliveryGrpInfos.air3PL.bookingDate = undefined
					this.deliveryGrpInfos.air3PL.bookingSlot = undefined
					this.deliveryGrpInfos.air3PL.bookingSlotTime = undefined
					this.deliveryGrpInfos.air3PL.zipSeq = v.zipSeq
					this.deliveryGrpInfos.air3PL.key = v.key
					this.deliveryGrpInfos.air3PL.address = v.address
					this.deliveryGrpInfos.air3PL.zipCode = v.zipCode
					agencyType.push(v)
				}
			})
			//타입별 1건 이하일 경우 개별적용 폼
			if (serviceType.length < 2 && agencyType.length < 2) {
				this.checkedAll = false
			}
		},
		checkIfOpenScheduleBook(orders) {
			return orders.some(v => !v.orderCancelYn)
		},
		changeRadioGroup(value) {
			this.radioGroup = value
		},
		initAppPage() {
			registerCallbackToApp({})
		},
		async alimTalk(reqCd) {
			const infoRes = await fetchWebOrderInfoById(this.mobOrderNo)
			const webOrderSum = JSON.parse(infoRes.resultObject.orderInfoJson)
				.PAY_SUMMARY_AMT

			const res = await fetchEContract({ mobOrderNo: this.mobOrderNo })
			const orderList = res.resultObject.data[0].setup
			const prdNm = orderList[0] ? orderList[0].GOODS_NM : '제품명'
			let count = orderList.length - 1
			let ordNoStr = ''
			orderList.forEach((res, i) => {
				if (i === 0) {
					ordNoStr = `${res.ORDER_NO}`
				} else {
					ordNoStr = `${ordNoStr}, ${res.ORDER_NO}`
				}
			})
			// 알림톡 발송
			let atParams = {
				reqCd: reqCd, // 1: 계약서발송, 2: 주문완료, 3: 서명완료재진입
				gubun: '1', // 1: 고객직접
				name:
					this.$store.getters['verify/customerType'] === 'L' // 사업자는 사업자명, 개인은 성명
						? this.$store.getters['verify/bizName']
						: this.$store.getters['verify/custName'],
				phone: this.$store.getters['verify/custPhone'],
				orderNo: ordNoStr,
				url: `${process.env.VUE_APP_FRT_URL}/order/confirm`, // 주문확정 링크
				orderInfoId: this.mobOrderNo, // 웹주문번호
				customerNo: this.$store.getters['verify/custNo'],
				codyNo: this.$store.getters['common/codyNo'],
				codyPhoneNo: this.$store.getters['common/codyPhoneNo'],
				goodsNm: prdNm,
				planDt: orderList[0].FIRST_DT ?? '',
				goodsInfo: count > 0 ? `${prdNm} 외 ${count}대` : `${prdNm}`,
				onceAmt: this.$options.filters.comma(webOrderSum.totalSumOfRecpAmt),
				monthAmt: this.$options.filters.comma(webOrderSum.totalSumOfMonthAmt)
			}

			await sendAlimTalk(atParams)
		}
	}
}
</script>

<style scoped lang="scss">
/**
	* @description 버튼 포커스 배경색이 계속 남아있는 현상
*/
.theme--light.v-btn:focus::before {
	opacity: 0 !important;
}
.v-btn:before {
	background-color: transparent !important;
}
.v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before {
	opacity: 0 !important;
}
</style>
